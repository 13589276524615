import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { LoginClient } from '../interface/interfaces';
import { of } from 'rxjs';
const token = 'auth_session';
const tokenClient = 'auth_client';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private router: Router, public auth: AuthService) {}

  signOut(): void {
    window.sessionStorage.clear();
    this.router.navigate(['/admin']);
  }

  public SaveToken(session: any): void {
    window.sessionStorage.removeItem(token);
    window.sessionStorage.setItem(token, JSON.stringify(session));
  }

  public getToken(): any {
    return JSON.parse(sessionStorage.getItem(token)!);
  }

  public getTokenClient(): LoginClient {
    return JSON.parse(sessionStorage.getItem(tokenClient)!);
  }

  signOutClient(): void {
    window.sessionStorage.clear();
    this.auth.isLoged = of(false);
    // this.router.navigate(['/myProfile']);
  }

  public SaveTokenClient(session: any): void {
    window.sessionStorage.removeItem(tokenClient);
    window.sessionStorage.setItem(tokenClient, JSON.stringify(session));
  }

  public verifySession(){
    const currentTime = Date.now();
    const session = this.getTokenClient();
    if (session) {
      const expirationTime = parseFloat(session.expire_token) * 1000;
      if (expirationTime > currentTime) {
        this.auth.isLoged = of(true);
        return session;
      } else {
        // La sesión ha expirado
        window.sessionStorage.removeItem(tokenClient);
        console.log('La sesión ha expirado.');
        this.auth.isLoged = of(false);
        return false;
      }
    } else {
      // La sesión ha expirado
      window.sessionStorage.removeItem(tokenClient);
      console.log('La sesión ha expirado.');
      this.auth.isLoged = of(false);
      return false;
    }
  }
}
