import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environments';
import { Login, LoginClient,ResponseSignUp,User, UserSignUp, menu } from '../interface/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string = environment.baseUrl;
  public isLoged!: Observable<boolean>;
  public tokenRecovery!:string;
  public emailRecovery!:string;
  public menu!: menu[];

  constructor(private http: HttpClient) { }

  login(user: User): Observable<Login> {
    const url = `${this.baseUrl}/Auth/login`;
    const body = {
      email: user.email,
      password: user.password
    }
    return this.http.post<Login>(url, body).pipe(
      catchError(error => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  loginCLient(user: User): Observable<LoginClient> {
    const url = `${this.baseUrl}/AuthClient/login`;
    const body = {
      email: user.email,
      password: user.password
    }
    return this.http.post<LoginClient>(url, body).pipe(
      catchError(error => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  verifyToken(token: string): Observable<any> {
    const url = `${this.baseUrl}/Auth/verifyToken`;
    const params = new HttpParams()
      .set('token', token);
    return this.http.get<any>(url, { params });
  }

  signUp(obj:UserSignUp):Observable<ResponseSignUp> {
    const url = `${this.baseUrl}/AuthClient/signup`;
    const body = {
      name: obj.name,
      lastName: obj.lastName,
      email: obj.email,
      password: obj.password,
      phone: obj.phone,
      birthdate: obj.birthdate
    }
    return this.http.post<ResponseSignUp>(url, body).pipe(
      catchError(error => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  sendOtp(email: string): Observable<any> {
    const url = `${this.baseUrl}/Verification/SendOtpMail`;

    const body = {
      email: email
    }
    return this.http.post<any>(url, body).pipe(
      catchError(error => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  confirmOtp(otp: string, token: string ): Observable<any> {
    const url = `${this.baseUrl}/Verification/ConfirmatedOtp`;

    const body = {
      otp: otp
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`  // Include Bearer token in the Authorization header
    };

    return this.http.post<any>(url, body, { headers: headers }).pipe(
      catchError(error => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }

  changePasswordOtp(password: string, token: string ): Observable<any> {
    const url = `${this.baseUrl}/Verification/ChangePasswordOtp`;

    const body = {
      password: password
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`  // Include Bearer token in the Authorization header
    };

    return this.http.post<any>(url, body, { headers: headers }).pipe(
      catchError(error => {
        console.error('Ha ocurrido un error:', error);
        return throwError('Ha ocurrido un error, por favor intenta de nuevo');
      })
    );
  }
}
